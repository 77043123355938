import React, { useEffect, useState } from "react";
import MdsCalendar from "../../../Components/MdsCalendar";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PatientsTable from "../../../Components/PatientsTable/PatientsTable";
import "./mds.css";

export default function RequestRx() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const patients = useSelector((state) => state.patient.patients);
  const [showEventModal, setShowEventModal] = useState(false);
  const [initialEvent, setInitialEvent] = useState(null);
  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {});
  };

  useEffect(() => {
    getPatients();
  }, []);

  useEffect(() => {
    // Check for stored event data when component mounts
    const storedEvent = sessionStorage.getItem("selectedMdsEvent");
    if (storedEvent) {
      try {
        const eventData = JSON.parse(storedEvent);
        setInitialEvent(eventData);
        setShowEventModal(true);
        // Clear the stored data
        sessionStorage.removeItem("selectedMdsEvent");
      } catch (error) {
        console.error("Error parsing stored event:", error);
      }
    }
  }, []);
  // Handle patient select
  const handlePatientSelect = (patient) => {
    setSelectedPatient(null); // Reset selected patient first
    setTimeout(() => {
      setSelectedPatient(patient); // Set selected patient after reset
    }, 0);
  };

  // Handle modal close
  const handleModalClose = () => {
    setSelectedPatient(null); // Clear selected patient on modal close
  };
  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-12 patientTableBox mt-30">
              <PatientsTable
                patients={patients}
                currentPage={currentPage}
                onSelect={handlePatientSelect}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <MdsCalendar
                selectedPatient={selectedPatient}
                onModalClose={handleModalClose}
                initialEvent={initialEvent}
                showEventModal={showEventModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
