// Create Deliveries
import React, { useEffect, useState } from "react";
import "./delivery.css";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import {
  fetchShelvesByPharmacyAction,
  assignShelfAction,
  reassignShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import { createDeliveryByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import {
  Modal,
  Accordion,
  Button,
  Form,
  ListGroup,
  Offcanvas,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import { MdHome, MdLocalHospital, MdOutlineOutbox } from "react-icons/md";
import { GiStorkDelivery } from "react-icons/gi";
import Overlay from "../../../Components/Overlay";

function Delivery() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryType, setDeliveryType] = useState("regular");
  const [deliveryStorage, setDeliveryStorage] = useState("ambient");
  const [deliveryStatus, setDeliveryStatus] = useState("assigned-driver");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [patientRef, setPatientRef] = useState("");
  const [routeRef, setRouteRef] = useState("");
  const [collectionRef, setCollectionRef] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("exempt");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("cash");
  // Reassign Shelf
  const [showReassignShelfModal, setShowReassignShelfModal] = useState(false);
  const [reassignCapacityNeeded, setReassignCapacityNeeded] = useState(1);
  const [reassignShelfId, setReassignShelfId] = useState("");
  const [editableCollection, setEditableCollection] = useState(null);

  const [showOffcanvasDeliveryModal, setShowOffcanvasDeliveryModal] =
    useState(false);
  const [showOffcanvasConfirmationModal, setShowOffcanvasConfirmationModal] =
    useState(false);
  // Add direct delivery modal
  const [
    showOffcanvasAddDirectDeliveryModal,
    setShowOffcanvasAddDirectDeliveryModal,
  ] = useState(false);
  // Main pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Pagination state for individual patients
  const [patientPagination, setPatientPagination] = useState({});
  const [searchPatientTerm, setSearchPatientTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  // Default values for shelf assignment
  const [selectedShelf, setSelectedShelf] = useState("");
  const [capacityNeeded, setCapacityNeeded] = useState(1);
  const collectionType = "delivery"; // Default collection type

  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);
  const routes = useSelector((state) => state.route.routes);
  const user = useSelector((state) => state.auth.user);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const allPatients = useSelector((state) => state.patient.patients);

  const userCurrentPlan = user.currentPlan;

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };

  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page on search
  };

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };

  const getPatientName = (patientId) => {
    const patient = allPatients.find((patient) => patient._id === patientId);
    return `${patient.firstName} ${patient.lastName}`;
  };

  const getRouteName = (routeId) => {
    const route = routes.find((route) => route._id === routeId);
    return `${route.name}`;
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowOffcanvasConfirmationModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      dispatch(switchCollectionType(currentCollectionId, newCollectionType));
      setShowOffcanvasConfirmationModal(false);
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `Collection type for ID ${currentCollectionId} switched to '${newCollectionType}'.`,
          user: user._id,
          forRole: ["admin"],
          priority: "medium",
        })
      );
    }
  };

  const filteredCollections = collections.filter((collection) => {
    const fullName = `${collection.patient?.firstName ?? ""} ${
      collection.patient?.middleName ?? ""
    } ${collection.patient?.lastName ?? ""}`.toLowerCase();

    const address = `${collection.patient?.addressLine1 ?? ""} ${
      collection.patient?.addressLine2 ?? ""
    } ${collection.patient?.townName ?? ""} ${
      collection.patient?.postCode ?? ""
    }`.toLowerCase();

    const nhsNumber = collection.patient?.nhsNumber?.toLowerCase() ?? "";

    const careHome = collection.patient?.careHome
      ? `${careHomeName(collection.patient.careHome)} ${careHomeAddress(
          collection.patient.careHome
        )}`.toLowerCase()
      : "";
    // if collection.patient is null, remove it from the search results
    if (!collection.patient) {
      return false;
    }
    return (
      collection.collectionType === "delivery" &&
      !collection.deliveryRef &&
      (fullName.includes(searchQuery) ||
        address.includes(searchQuery) ||
        nhsNumber.includes(searchQuery) ||
        careHome.includes(searchQuery))
    );
  });

  const groupCollectionsByPatient = (collections) => {
    return collections.reduce((acc, collection) => {
      const patientId = collection?.patient?._id;
      if (!acc[patientId]) {
        acc[patientId] = {
          patient: collection.patient,
          collections: [],
        };
      }
      acc[patientId].collections.push(collection);
      return acc;
    }, {});
  };

  const groupedCollections = groupCollectionsByPatient(filteredCollections);

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(date - timeZoneOffset)
      .toISOString()
      .slice(0, 16);
    return localISOTime;
  };

  const openDeliveryModal = (collection = null) => {
    if (collection) {
      setDeliveryDate(formatDateForInput(collection.deliveryDate));
      setDeliveryType(collection.deliveryType);
      setDeliveryStorage(collection.deliveryStorage);
      setDeliveryStatus(collection.status);
      setDeliveryNote(collection.deliveryNote || "");
      setPatientRef(collection.patient?._id || "");
      setCollectionRef(collection._id);
      setPaymentMethod(collection.paymentOnDelivery?.method || "exempt");
      setPaymentAmount(collection.paymentOnDelivery?.amount || 0);
      setPaymentType(collection.paymentOnDelivery?.paymentType || "cash");
    } else {
      setDeliveryDate("");
      setDeliveryType("regular");
      setDeliveryStorage("ambient");
      setDeliveryStatus("assigned-driver");
      setDeliveryNote("");
      setPatientRef("");
      setCollectionRef("");
      setPaymentMethod("exempt");
      setPaymentAmount(0);
      setPaymentType("cash");
    }
    setShowOffcanvasDeliveryModal(true);
  };

  const handleSave = () => {
    const deliveryData = {
      deliveryDate,
      deliveryType,
      deliveryStorage,
      deliveryNote,
      status: deliveryStatus,
      patientRef,
      collectionRef,
      routeRef,
      paymentOnDelivery: {
        method: paymentMethod,
        status: paymentMethod === "exempt" ? "collected" : "pending",
        amount: paymentAmount,
        paymentType,
      },
    };
    dispatch(createDeliveryByPharmacyAction(pharmacyId, deliveryData))
      .then(() => {
        setShowOffcanvasDeliveryModal(false);
        dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Delivery created successfully for patient ${getPatientName(
              patientRef
            )}
              with delivery date ${deliveryDate} and delivery type ${deliveryType} and assigned route ${getRouteName(
              routeRef
            )}.
            `,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
      })
      .catch((error) => {
        console.error("Error creating/updating delivery:", error);
      });
  };

  const getRoutes = () => {
    try {
      dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Routes could not be fetched");
    }
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId));
  };

  // Main pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGroupedCollections = Object.entries(groupedCollections).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(
    Object.keys(groupedCollections).length / itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handlePrevPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page on changing items per page
  };

  // Pagination for collections inside each accordion
  const handlePatientPageChange = (patientId, pageNumber) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: { ...prev[patientId], currentPage: pageNumber },
    }));
  };

  const handlePatientItemsPerPageChange = (patientId, itemsPerPage) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: { ...prev[patientId], itemsPerPage, currentPage: 1 },
    }));
  };

  const getPaginatedCollections = (patientId, collections) => {
    const patientCurrentPage = patientPagination[patientId]?.currentPage || 1;
    const patientItemsPerPage = patientPagination[patientId]?.itemsPerPage || 5;
    const indexOfLastItem = patientCurrentPage * patientItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - patientItemsPerPage;
    return collections.slice(indexOfFirstItem, indexOfLastItem);
  };

  // Handle patient search
  // Function to close the modal and reset fields
  const handleCloseAddDirectDeliveryModal = () => {
    setShowOffcanvasAddDirectDeliveryModal(false);
    setSearchPatientTerm("");
    setFilteredPatients([]);
    setSelectedPatient(null);
  };

  // Handle direct delivery creation
  const handleAddDirectDelivery = async () => {
    try {
      if (!selectedPatient) {
        ErrorNotification("Please select a patient before creating delivery.");
        return;
      }

      if (!selectedShelf) {
        ErrorNotification("Please select a shelf.");
        return;
      }

      const shelfName = shelves.find(
        (shelf) => shelf._id === selectedShelf
      )?.name;

      // Assign the selected shelf to the patient
      const res = await dispatch(
        assignShelfAction(
          pharmacyId,
          selectedShelf,
          capacityNeeded,
          selectedPatient._id,
          collectionType
        )
      );
      console.log(res); // Log to inspect the structure of the response

      if (!res || !res.collectionRef) {
        console.error("No collectionRef found in the response:", res);
        throw new Error("Collection reference not found in response.");
      }

      const newCollectionRef = res?.collectionRef;

      // Once shelf is assigned, create the delivery
      const deliveryData = {
        deliveryDate,
        deliveryType,
        deliveryStorage,
        deliveryNote,
        status: "assigned-driver",
        patientRef: selectedPatient._id,
        collectionRef: newCollectionRef,
        routeRef,
        paymentOnDelivery: {
          method: paymentMethod,
          status: paymentMethod === "exempt" ? "collected" : "pending",
          amount: paymentAmount,
          paymentType,
        },
      };

      await dispatch(createDeliveryByPharmacyAction(pharmacyId, deliveryData));

      // Notify about the successful shelf assignment
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `${selectedPatient?.firstName} ${selectedPatient.lastName} bag stored in ${shelfName}`,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "high",
        })
      );

      handleCloseAddDirectDeliveryModal();
      dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
    } catch (error) {
      console.error("Error creating direct delivery:", error);
      ErrorNotification("An error occurred while creating the delivery.");
    }
  };

  const handleReassignShelf = () => {
    console.log(
      editableCollection,
      reassignShelfId,
      reassignCapacityNeeded,
      "data"
    );
    if (!reassignShelfId || !editableCollection || !editableCollection._id) {
      ErrorNotification(
        "Please select a valid shelf and specify the capacity needed."
      );
      return;
    }

    dispatch(
      reassignShelfAction(
        pharmacyId,
        editableCollection._id,
        reassignShelfId,
        reassignCapacityNeeded
      )
    )
      .then(() => {
        dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        setShowReassignShelfModal(false);
        // Add notification for successful reassignment
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf reassigned successfully for collection ID ${reassignShelfId} from shelf ${getShelfNameByCollection(
              editableCollection._id
            )} to shelf ${getShelfNameByCollection(reassignShelfId)}.
            `,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "medium",
          })
        );
      })
      .catch((err) => {
        ErrorNotification(
          "Error during shelf reassignment: " + (err.message || "Unknown error")
        );
      });
  };

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    getCareHomes();
    getRoutes();
    getPatients();
  }, [dispatch, pharmacyId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  // Handle patient search
  useEffect(() => {
    if (searchPatientTerm) {
      const filtered = allPatients
        .filter(
          (patient) =>
            patient.displayName
              .toLowerCase()
              .includes(searchPatientTerm.toLowerCase()) ||
            patient.nhsNumber.includes(searchPatientTerm)
        )
        .slice(0, 5); // Limit to 5 results
      setFilteredPatients(filtered);
    } else {
      setFilteredPatients([]);
    }
  }, [searchPatientTerm, allPatients]);

  return (
    <div className="create-delivery-container">
      {userCurrentPlan === "Free" && <Overlay />}
      <div className="create-delivery-search-input-box">
        <input
          type="text"
          placeholder="Search By Name, NHS, Address"
          onChange={handleSearchChange}
          className="create-delivery-search-input"
          value={searchQuery}
        />
      </div>

      <div className="create-delivery-header">
        {/* Add a patient directly */}
        <Button
          className="create-delivery-invite-btn"
          onClick={() => setShowOffcanvasAddDirectDeliveryModal(true)}
        >
          Add Direct Delivery
        </Button>
        <div className="create-delivery-legends">
          <div className="create-delivery-legend-item">
            <GiStorkDelivery className="create-delivery-legend-icon create-delivery-legend-collected-by" />
            <span className="create-delivery-legend-text">Create Delivery</span>
          </div>
          <div className="create-delivery-legend-item">
            <MdHome className="create-delivery-legend-icon create-delivery-legend-patients-address" />
            <span className="create-delivery-legend-text">Patient Address</span>
          </div>
          <div className="create-delivery-legend-item">
            <MdLocalHospital className="create-delivery-legend-icon create-delivery-legend-carehome-address" />
            <span className="create-delivery-legend-text">
              Care Home Address
            </span>
          </div>
          <div className="create-delivery-legend-item">
            <MdOutlineOutbox className="create-delivery-legend-icon create-delivery-legend-reassign-shelf" />
            <span className="create-delivery-legend-text">Reassign Shelf</span>
          </div>
        </div>
      </div>

      <div className="create-delivery-items-per-page-selector">
        <select
          className="create-delivery-form-select"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {[5, 10, 15, 20].map((number) => (
            <option key={number} value={number}>
              {number} Patients/page
            </option>
          ))}
        </select>
      </div>

      <div className="create-delivery-accordion-container">
        <Accordion>
          {currentGroupedCollections.map(([patientId, data], index) => {
            const paginatedCollections = getPaginatedCollections(
              patientId,
              data.collections
            );
            const totalPatientPages = Math.ceil(
              data.collections.length /
                (patientPagination[patientId]?.itemsPerPage || 5)
            );

            return (
              <Accordion.Item eventKey={index.toString()} key={patientId}>
                <Accordion.Header>
                  <div className="create-delivery-header">
                    <div>
                      <p className="create-delivery-accordion-patient-name">
                        {data.patient?.firstName} {data.patient?.lastName}{" "}
                      </p>
                      <p className="create-delivery-accordion-patient-nhs">
                        NHS: {data.patient?.nhsNumber}
                      </p>
                    </div>
                    <div className="create-delivery-accordion-patient-address">
                      {data.patient?.careHome ? (
                        <div className="create-delivery-create-patient-address">
                          <div className="d-flex align-items-center">
                            <MdLocalHospital
                              size={24}
                              color="#0cc763"
                              style={{ marginRight: "8px" }}
                            />
                          </div>
                          <div>
                            <p className="create-delivery-create-patient-carehome-name">
                              {careHomeName(data.patient?.careHome)}
                            </p>
                            <div>{careHomeAddress(data.patient?.careHome)}</div>
                          </div>
                        </div>
                      ) : (
                        <div className="create-delivery-create-patient-address">
                          <div className="d-flex align-items-center">
                            <MdHome
                              size={24}
                              color="#ffb300"
                              style={{ marginRight: "8px" }}
                            />
                          </div>
                          <div>
                            {data.patient?.addressLine1 &&
                              `${
                                data.patient?.addressLine1.trim().endsWith(",")
                                  ? data.patient?.addressLine1
                                  : data.patient?.addressLine1 + ","
                              } `}
                            {data.patient?.addressLine2 &&
                              `${
                                data.patient?.addressLine2.trim().endsWith(",")
                                  ? data.patient?.addressLine2
                                  : data.patient?.addressLine2 + ","
                              } `}
                            {data.patient?.townName &&
                              `${data.patient?.townName}, `}
                            {data.patient?.postCode}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="create-delivery-table-container">
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr>
                          <th>Shelf Name</th>
                          <th>Status</th>
                          <th>Collection Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedCollections.map(
                          (collection, collectionIndex) => (
                            <tr key={collectionIndex}>
                              <td>
                                {getShelfNameByCollection(collection._id)}
                              </td>
                              <td>{collection.status}</td>
                              <td>
                                <select
                                  value={collection.collectionType}
                                  onChange={(e) =>
                                    promptSwitchCollectionType(
                                      collection._id,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="delivery">Delivery</option>
                                  <option value="handout">Handout</option>
                                </select>
                              </td>
                              <td>
                                <div className="create-delivery-collect-button-container">
                                  <Button
                                    className="create-delivery-collect-button"
                                    onClick={() =>
                                      openDeliveryModal(collection)
                                    }
                                  >
                                    <GiStorkDelivery />
                                  </Button>
                                  <Button
                                    className="create-delivery-reassign-button"
                                    onClick={() => {
                                      setEditableCollection(collection);
                                      setShowReassignShelfModal(true);
                                    }}
                                  >
                                    <MdOutlineOutbox />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    {/* Pagination for collections inside accordion */}
                    <div className="page-number-box">
                      <div>
                        <select
                          className="form-select"
                          value={
                            patientPagination[patientId]?.itemsPerPage || 5
                          }
                          onChange={(e) =>
                            handlePatientItemsPerPageChange(
                              patientId,
                              Number(e.target.value)
                            )
                          }
                        >
                          {[5, 10, 15, 20].map((number) => (
                            <option key={number} value={number}>
                              {number} Collections/page
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pagination">
                        {[...Array(totalPatientPages).keys()].map(
                          (pageNumber) => (
                            <Button
                              key={pageNumber}
                              onClick={() =>
                                handlePatientPageChange(
                                  patientId,
                                  pageNumber + 1
                                )
                              }
                              className={`page-link ${
                                (patientPagination[patientId]?.currentPage ||
                                  1) ===
                                pageNumber + 1
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {pageNumber + 1}
                            </Button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
      <div className="page-number-box">
        {/* Pagination */}
        <div className="create-delivery-pagination">
          <Button
            className="create-delivery-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="create-delivery-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="create-delivery-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        show={showOffcanvasConfirmationModal}
        onHide={() => setShowOffcanvasConfirmationModal(false)}
        centered
        className="create-delivery-confirmation-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Switch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to switch the collection type?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowOffcanvasConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSwitchCollectionType}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Create Delivery Modal */}
      <Offcanvas
        show={showOffcanvasDeliveryModal}
        onHide={() => setShowOffcanvasDeliveryModal(false)}
        className="create-delivery-offcanvas"
        placement="end"
      >
        <Offcanvas.Header
          closeButton
          className="create-delivery-offcanvas-header"
        >
          <Offcanvas.Title>
            {collectionRef ? "Assign Delivery" : "Create Delivery"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="create-delivery-offcanvas-body">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="deliveryDate">Delivery Date</label>
              <input
                type="date"
                className="form-control"
                id="deliveryDate"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="deliveryType">Delivery Type</label>
              <select
                className="form-control"
                id="deliveryType"
                value={deliveryType}
                onChange={(e) => setDeliveryType(e.target.value)}
              >
                <option value="regular">Regular</option>
                <option value="express">Express</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="deliveryStorage">Delivery Storage</label>
              <select
                className="form-control"
                id="deliveryStorage"
                value={deliveryStorage}
                onChange={(e) => setDeliveryStorage(e.target.value)}
              >
                <option value="ambient">Ambient</option>
                <option value="refrigerated">Refrigerated</option>
                <option value="frozen">Frozen</option>
                <option value="controlled substance">
                  Controlled Substance
                </option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="routeRef">Assign Route</label>
              <select
                className="form-control"
                id="routeRef"
                value={routeRef}
                required
                onChange={(e) => setRouteRef(e.target.value)}
              >
                <option value="">Select a Route</option>
                {routes
                  .filter((route) => route.activeStatus)
                  .map((route) => (
                    <option key={route._id} value={route._id}>
                      {route.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Payment Method</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="exempt"
                  name="paymentMethod"
                  value="exempt"
                  checked={paymentMethod === "exempt"}
                  onChange={() => setPaymentMethod("exempt")}
                />
                <label
                  htmlFor="exempt"
                  className="mr-3"
                  style={{ marginRight: "10px" }}
                >
                  Exempt
                </label>
                <input
                  type="radio"
                  id="collect"
                  name="paymentMethod"
                  value="collect"
                  checked={paymentMethod === "collect"}
                  onChange={() => setPaymentMethod("collect")}
                />
                <label htmlFor="collect">Collect</label>
              </div>
            </div>
            {paymentMethod === "collect" && (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="paymentAmount">Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="paymentAmount"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="paymentType">Payment Type</label>
                  <select
                    className="form-control"
                    id="paymentType"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option value="cash">Cash</option>
                    {/* Add more options as needed for future phases */}
                  </select>
                </div>
              </>
            )}

            <div className="form-group mb-3">
              <label htmlFor="deliveryNote">Delivery Note</label>
              <textarea
                className="form-control"
                id="deliveryNote"
                value={deliveryNote}
                onChange={(e) => setDeliveryNote(e.target.value)}
              />
            </div>
          </form>
        </Offcanvas.Body>
        <div className="create-delivery-offcanvas-footer">
          <button
            className="btn btn-secondary"
            onClick={() => setShowOffcanvasDeliveryModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </button>
        </div>
      </Offcanvas>

      {/* Add Direct Delivery Modal */}
      <Offcanvas
        show={showOffcanvasAddDirectDeliveryModal}
        onHide={handleCloseAddDirectDeliveryModal}
        className="create-delivery-offcanvas"
        placement="end"
      >
        <Offcanvas.Header
          closeButton
          className="create-delivery-offcanvas-header"
        >
          <Offcanvas.Title className="create-delivery-offcanvas-title">
            Add Direct Delivery
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="create-delivery-offcanvas-body">
          <Form>
            {/* Search Patient */}
            <Form.Group>
              <Form.Label>Search Patient by Name or NHS Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchPatientTerm}
                onChange={(e) => setSearchPatientTerm(e.target.value)}
                disabled={!!selectedPatient}
              />
              <ListGroup>
                {filteredPatients.map((patient) => (
                  <ListGroup.Item
                    key={patient._id}
                    onClick={() => setSelectedPatient(patient)}
                  >
                    {patient.firstName} {patient.lastName} - {patient.nhsNumber}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>

            {/* If patient is selected, show more form details */}
            {selectedPatient && (
              <>
                <Form.Group>
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>NHS Number</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={selectedPatient.nhsNumber}
                  />
                </Form.Group>

                {/* Add more fields for delivery details */}
                <Form.Group>
                  <Form.Label>Delivery Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryType}
                    onChange={(e) => setDeliveryType(e.target.value)}
                  >
                    <option value="regular">Regular</option>
                    <option value="express">Express</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Delivery Storage</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryStorage}
                    onChange={(e) => setDeliveryStorage(e.target.value)}
                  >
                    <option value="ambient">Ambient</option>
                    <option value="refrigerated">Refrigerated</option>
                    <option value="frozen">Frozen</option>
                  </Form.Control>
                </Form.Group>

                {/* Shelf Selection */}
                <Form.Group>
                  <Form.Label>Select Shelf</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedShelf}
                    onChange={(e) => setSelectedShelf(e.target.value)}
                  >
                    <option value="">Select Shelf</option>
                    {shelves.map((shelf) => {
                      const remainingCapacity =
                        shelf.shelfCapacity - shelf.occupiedCapacity;
                      return (
                        <option key={shelf._id} value={shelf._id}>
                          {shelf.name} - {shelf.packageSize} (Remaining
                          Capacity: {remainingCapacity})
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Route</Form.Label>
                  <Form.Control
                    as="select"
                    value={routeRef}
                    onChange={(e) => setRouteRef(e.target.value)}
                  >
                    <option value="">Select a Route</option>
                    {routes
                      .filter((route) => route.activeStatus)
                      .map((route) => (
                        <option key={route._id} value={route._id}>
                          {route.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Payment Method</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="radio"
                      label="Exempt"
                      value="exempt"
                      checked={paymentMethod === "exempt"}
                      onChange={() => setPaymentMethod("exempt")}
                    />
                    <Form.Check
                      type="radio"
                      label="Collect"
                      value="collect"
                      checked={paymentMethod === "collect"}
                      onChange={() => setPaymentMethod("collect")}
                      className="ml-3"
                    />
                  </div>
                </Form.Group>

                {paymentMethod === "collect" && (
                  <>
                    <Form.Group>
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        value={paymentAmount}
                        onChange={(e) => setPaymentAmount(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Payment Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        <option value="cash">Cash</option>
                        <option value="card">Card</option>
                      </Form.Control>
                    </Form.Group>
                  </>
                )}
                <Form.Group>
                  <Form.Label>Delivery Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={deliveryNote}
                    onChange={(e) => setDeliveryNote(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="create-delivery-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={handleCloseAddDirectDeliveryModal}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddDirectDelivery}
            disabled={!selectedPatient || !selectedShelf}
          >
            Save Delivery
          </Button>
        </div>
      </Offcanvas>

      {/* Reassign Shelf Modal */}
      <Offcanvas
        show={showReassignShelfModal}
        onHide={() => setShowReassignShelfModal(false)}
        placement="end"
        className="create-delivery-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="create-delivery-offcanvas-header"
        >
          <Offcanvas.Title className="create-delivery-offcanvas-title">
            Reassign Shelf
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="create-delivery-offcanvas-body">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select New Shelf</Form.Label>
              <Form.Select
                value={reassignShelfId}
                onChange={(e) => setReassignShelfId(e.target.value)}
              >
                <option value="">Select a shelf...</option>
                {shelves.map((shelf) => (
                  <option
                    key={shelf._id}
                    value={shelf._id}
                    disabled={
                      shelf.occupiedCapacity + reassignCapacityNeeded >
                      shelf.shelfCapacity
                    }
                  >
                    {shelf.name} ({shelf.occupiedCapacity}/{shelf.shelfCapacity}{" "}
                    occupied)
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Capacity Needed</Form.Label>
              <Form.Control
                type="number"
                value={reassignCapacityNeeded}
                onChange={(e) =>
                  setReassignCapacityNeeded(parseInt(e.target.value))
                }
                min="1"
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="create-delivery-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={() => setShowReassignShelfModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReassignShelf}
            disabled={!reassignShelfId}
          >
            Reassign Shelf
          </Button>
        </div>
      </Offcanvas>
    </div>
  );
}

export default Delivery;
