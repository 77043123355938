import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Table, Form, Offcanvas } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import {
  fetchShelvesByPharmacyAction,
  assignShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import { createDeliveryByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { fetchCollectionsByPharmacyAction } from "../../../redux/actions/collectionActions";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { MdHome, MdLocalHospital } from "react-icons/md";
import { BsBookshelf } from "react-icons/bs";
import { BsCollection } from "react-icons/bs";
import "./medicineStorage.css";
import { RiErrorWarningFill } from "react-icons/ri";

const formatDateForDisplay = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

const formatDateForSearch = (dateString) => {
  const date = new Date(dateString);
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};

function MedicineStorage() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchPatient, setSearchPatient] = useState("");
  const [selectedShelf, setSelectedShelf] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [capacityNeeded, setCapacityNeeded] = useState(1);
  const [collectionType, setCollectionType] = useState("handout");
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  // Delivery
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryType, setDeliveryType] = useState("regular");
  const [deliveryStorage, setDeliveryStorage] = useState("ambient");
  const [deliveryStatus, setDeliveryStatus] = useState("assigned-driver");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [patientRef, setPatientRef] = useState("");
  const [routeRef, setRouteRef] = useState("");
  const [collectionRef, setCollectionRef] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("exempt");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("cash");
  const [wantDirectDelivery, setWantDirectDelivery] = useState(false);
  const [agreeToDirectDelivery, setAgreeToDirectDelivery] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Track
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [pendingCollectionAction, setPendingCollectionAction] = useState(null);

  // Pages
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Pagination for occupied shelves
  const [occupiedShelfPage, setOccupiedShelfPage] = useState(1);
  const [occupiedShelfItemsPerPage, setOccupiedShelfItemsPerPage] = useState(5);

  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const routes = useSelector((state) => state.route.routes);
  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };
  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const getShelves = () => {
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  };
  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {
      console.error("Error fetching patients:", err);
    });
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  const getCollections = () => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
  };

  const getRoutes = () => {
    dispatch(fetchRoutesByPharmacyAction(pharmacyId));
  };

  useEffect(() => {
    getPatients();
    getShelves();
    getCareHomes();
    getCollections();
    getRoutes();
  }, []);

  const patients = useSelector((state) =>
    state.patient.patients.map((patient) => {
      return {
        ...patient,
        dateOfBirth: formatDateForDisplay(patient.dateOfBirth),
      };
    })
  );

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchPatient(value);
    setCurrentPage(1);
  };

  const filteredPatients = patients.filter((patient) => {
    const dobFormatted = formatDateForSearch(patient.dateOfBirth);
    const monthName = new Date(patient.dateOfBirth)
      .toLocaleString("en-GB", { month: "short" })
      .toLowerCase();
    const address = patient.addressLine1?.toLowerCase() || "";
    const postcode = patient.postCode?.toLowerCase() || "";
    const careHome = patient.careHome
      ? `${careHomeName(patient.careHome)} ${careHomeAddress(
          patient.careHome
        )}`.toLowerCase()
      : "";

    const searchWords = searchPatient.split(" ");

    // Check if every word in the search query matches any of the patient's details
    return searchWords.every(
      (word) =>
        patient.firstName.toLowerCase().includes(word) ||
        patient.lastName.toLowerCase().includes(word) ||
        patient.middleName?.toLowerCase().includes(word) ||
        patient.nhsNumber.toLowerCase().includes(word) ||
        dobFormatted.includes(word) ||
        monthName.includes(word) ||
        address.includes(word) ||
        postcode.includes(word) ||
        careHome.includes(word)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handlePrevPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const toggleShelfModal = () => {
    setIsTableOpen(!isTableOpen);
  };

  const remainingCapacity = (shelfCapacity, occupiedCapacity) => {
    return shelfCapacity - occupiedCapacity;
  };
  const validateDeliveryFields = () => {
    if (!deliveryDate) return "Delivery date is required";
    if (!routeRef) return "Route selection is required";
    if (paymentMethod === "collect" && !paymentAmount)
      return "Payment amount is required";
    return null;
  };

  const handleAddCollection = async () => {
    if (!selectedShelf || !selectedPatient._id || !collectionType) {
      ErrorNotification(
        "Missing required fields for assigning a shelf",
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      );
      setIsProcessing(false);
      return;
    }

    // Check if collection type matches patient service type
    const serviceTypeCheck =
      selectedPatient.service === "delivery" ? "delivery" : "handout";
    const existingCollection = collections.find(
      (col) =>
        col.patient?._id === selectedPatient._id &&
        col.status === "Pending" &&
        col.collectionType === collectionType
    );

    let warnings = [];

    if (serviceTypeCheck !== collectionType) {
      warnings.push(
        `• You are trying to add a patient who has ${serviceTypeCheck} service type but you are creating a ${collectionType}`
      );
    }

    if (existingCollection) {
      warnings.push(
        `• A collection of type "${collectionType}" for this patient already exists`
      );
    }

    if (warnings.length > 0) {
      setWarningMessage(
        <>
          <p className="warning-heading">Please note:</p>
          {warnings.map((warning, index) => (
            <p key={index} className="warning-item">
              {warning}
            </p>
          ))}
          <p className="warning-question">Are you sure you want to proceed?</p>
        </>
      );
      setShowWarningModal(true);

      // Create a promise that resolves when the user makes a choice
      return new Promise((resolve) => {
        setPendingCollectionAction(() => async () => {
          await performCollectionCreation();
          resolve();
        });
      });
    }

    // If no warnings, proceed directly
    await performCollectionCreation();
  };

  // Helper function that contains the actual collection creation logic
  const performCollectionCreation = async () => {
    setIsProcessing(true);
    const shelfName = shelves.find(
      (shelf) => shelf._id === selectedShelf
    )?.name;

    try {
      const res = await dispatch(
        assignShelfAction(
          pharmacyId,
          selectedShelf,
          capacityNeeded,
          selectedPatient._id,
          collectionType
        )
      );

      if (res && res.collectionRef) {
        await dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        await dispatch(fetchCollectionsByPharmacyAction(pharmacyId));

        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `${selectedPatient.firstName} ${selectedPatient.lastName} bag stored in ${shelfName} shelf.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );

        if (wantDirectDelivery && agreeToDirectDelivery) {
          await handleAddDelivery(res.collectionRef);
        }

        setShowAddCollectionModal(false);
      } else {
        console.error("No collectionRef in response:", res);
        ErrorNotification("Failed to get collection reference");
      }
    } catch (error) {
      console.error("Error in handleAddCollection:", error);
      ErrorNotification("Failed to assign shelf or create delivery.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddDelivery = async (collectionRef) => {
    if (!collectionRef) {
      ErrorNotification("Missing collection reference for delivery");
      return;
    }
    const validationError = validateDeliveryFields();
    if (validationError) {
      ErrorNotification(validationError);
      return;
    }
    const deliveryData = {
      deliveryDate,
      deliveryType,
      deliveryStorage,
      deliveryNote,
      status: deliveryStatus,
      patientRef: selectedPatient._id,
      collectionRef, // Using the collectionRef parameter directly
      routeRef,
      paymentOnDelivery: {
        method: paymentMethod,
        status: paymentMethod === "exempt" ? "collected" : "pending",
        amount: paymentAmount,
        paymentType,
      },
    };

    try {
      const result = dispatch(
        createDeliveryByPharmacyAction(pharmacyId, deliveryData)
      );

      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `Delivery created successfully for ${selectedPatient.firstName} with date ${deliveryDate}.`,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "high",
        })
      );
      return result;
    } catch (error) {
      console.error("Error creating delivery:", error);
      ErrorNotification("Failed to create delivery.");
    }
  };

  const handleCloseModal = () => {
    setShowAddCollectionModal(false);
    setSelectedShelf(""); // Reset the selected shelf when the modal is closed
    // Reset the form fields
    setDeliveryDate("");
    setDeliveryType("regular");
    setDeliveryStorage("ambient");
    setDeliveryStatus("assigned-driver");
    setDeliveryNote("");
    setPatientRef("");
    setRouteRef("");
    setCollectionRef("");
    setPaymentMethod("exempt");
    setPaymentAmount(0);
    setPaymentType("cash");
    setWantDirectDelivery(false);
    setAgreeToDirectDelivery(false);
  };

  // Pagination for occupied shelves
  const handlePageChangeOccupiedShelves = (pageNumber, e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the modal from closing
    setOccupiedShelfPage(pageNumber);
  };

  const handleItemsPerPageChangeOccupiedShelves = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the modal from closing
    setOccupiedShelfItemsPerPage(Number(e.target.value));
    setOccupiedShelfPage(1); // Reset to the first page whenever items per page change
  };

  const getOccupiedShelvesByPatient = (patientId) => {
    return shelves
      ?.map((shelf) => {
        const patientCollections = shelf?.collectionRef
          ?.map((collectionId) =>
            collections?.find(
              (col) =>
                col?._id === collectionId && col?.patient?._id === patientId
            )
          )
          .filter((col) => col);

        const userOccupiedCapacity = patientCollections.reduce(
          (total, collection) => total + collection.capacityNeeded,
          0
        );

        return {
          ...shelf,
          userOccupiedCapacity,
          remainingCapacity: remainingCapacity(
            shelf.shelfCapacity,
            shelf.occupiedCapacity
          ),
          createdAt: patientCollections.length
            ? patientCollections[0].createdAt
            : "",
          updatedAt: patientCollections.length
            ? patientCollections[0].updatedAt
            : "",
        };
      })
      .filter((shelf) => shelf.userOccupiedCapacity > 0); // Only return shelves with capacity occupied by the user
  };

  const occupiedShelves = getOccupiedShelvesByPatient(selectedPatient._id);

  // Pagination logic for occupied shelves
  const indexOfLastOccupiedShelf =
    occupiedShelfPage * occupiedShelfItemsPerPage;
  const indexOfFirstOccupiedShelf =
    indexOfLastOccupiedShelf - occupiedShelfItemsPerPage;
  const currentOccupiedShelves = occupiedShelves.slice(
    indexOfFirstOccupiedShelf,
    indexOfLastOccupiedShelf
  );
  const totalOccupiedShelfPages = Math.ceil(
    occupiedShelves.length / occupiedShelfItemsPerPage
  );

  return (
    <div className="storage-container">
      {userCurrentPlan === "Free" && (
        <div className="information-text">
          <p>Setting Collection type to "delivery is disabled for Free Plan</p>
        </div>
      )}
      <div className="storage-search-input-box">
        <input
          type="text"
          value={searchPatient}
          placeholder="Search By Name, NHS, Address, DOB, Postcode"
          className="storage-search-input"
          onChange={handleSearchChange}
        />
      </div>
      <div className="storage-header">
        <Button onClick={toggleShelfModal} className="storage-view-shelf-btn">
          <BsBookshelf className="storage-view-shelf-icon" />
          {isTableOpen ? "Close Shelf Capacity" : "View Shelf Capacity"}
        </Button>
        {/* Legends */}
        <div className="storage-legends">
          <div className="storage-legend-item">
            <MdHome className="storage-legend-icon storage-legend-patients-address" />
            <span className="storage-legend-text">Patient Address</span>
          </div>
          <div className="storage-legend-item">
            <MdLocalHospital className="storage-legend-icon storage-legend-carehome-address" />
            <span className="storage-legend-text">Care Home Address</span>
          </div>
          <div className="storage-legend-item">
            <BsCollection className="storage-legend-icon storage-legend-collection-icon" />
            <span className="storage-legend-text">Add Collection</span>
          </div>
        </div>
      </div>

      <div className="storage-table-container">
        <table className="table table-bordered w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>NHS Number</th>
              <th>DOB</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentPatients.map((patient) => (
              <tr key={patient._id}>
                <td>{patient.firstName + " " + patient.lastName}</td>
                <td>{patient.nhsNumber}</td>
                <td>{patient.dateOfBirth}</td>
                <td>{patient.mobileNo}</td>
                <td>
                  {patient.careHome ? (
                    <div className="create-patient-address">
                      <div className="d-flex align-items-center">
                        <MdLocalHospital
                          size={24}
                          color="#0cc763"
                          style={{ marginRight: "8px" }}
                        />
                      </div>
                      <div>
                        <p className="create-patient-carehome-name">
                          {careHomeName(patient.careHome)}
                        </p>
                        <div>{careHomeAddress(patient.careHome)}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="create-patient-address">
                      <div className="d-flex align-items-center">
                        <MdHome
                          size={24}
                          color="#ffb300"
                          style={{ marginRight: "8px" }}
                        />
                      </div>
                      <div>
                        {patient.addressLine1 &&
                          `${
                            patient.addressLine1.trim().endsWith(",")
                              ? patient.addressLine1
                              : patient.addressLine1 + ","
                          } `}
                        {patient.addressLine2 &&
                          `${
                            patient.addressLine2.trim().endsWith(",")
                              ? patient.addressLine2
                              : patient.addressLine2 + ","
                          } `}
                        {patient.townName && `${patient.townName}, `}
                        {patient.postCode}
                      </div>
                    </div>
                  )}
                </td>
                <td>
                  <BsCollection
                    className="storage-action-icon storage-add-icon"
                    onClick={() => {
                      setSelectedPatient(patient);
                      setShowAddCollectionModal(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="page-number-box">
          {/* <div>
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              {[5, 10, 15, 20].map((number) => (
                <option key={number} value={number}>
                  {number} Patients/page
                </option>
              ))}
            </select>
          </div> */}

          <div className="storage-pagination">
            <Button
              className="storage-pagination-btn"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span className="storage-page-number">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              className="storage-pagination-btn"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
      </div>

      {/* Assign Shelf Modal */}
      <Offcanvas
        show={showAddCollectionModal}
        onHide={handleCloseModal}
        placement="end"
        className="medicine-storage-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="medicine-storage-offcanvas-header"
        >
          <Offcanvas.Title className="medicine-storage-offcanvas-title">
            Assign Shelf
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="medicine-storage-offcanvas-body">
          <Form>
            {/* Display selected patient info */}
            <div className="selected-patient-info mb-4">
              <h5>Selected Patient</h5>
              <p>
                <strong>Name:</strong> {selectedPatient.firstName}{" "}
                {selectedPatient.lastName}
                <br />
                <strong>NHS Number:</strong> {selectedPatient.nhsNumber}
              </p>
            </div>
            {/* Table showing all shelves occupied by the selected patient */}
            {occupiedShelves.length > 0 && (
              <>
                <h5>Occupied Shelves</h5>
                <Table striped bordered hover>
                  <thead className="occupied-shelves-table">
                    <tr>
                      <th>Shelf Name</th>
                      <th>Package Size</th>
                      <th>Patient Occupied Capacity</th>
                    </tr>
                  </thead>
                  <tbody className="occupied-shelves-table">
                    {currentOccupiedShelves.map((shelf) => (
                      <tr key={shelf._id}>
                        <td>{shelf.name}</td>
                        <td>{shelf.packageSize}</td>
                        <td>{shelf.userOccupiedCapacity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="page-number-box">
                  <div>
                    <select
                      className="form-select"
                      value={occupiedShelfItemsPerPage}
                      onChange={(e) =>
                        handleItemsPerPageChangeOccupiedShelves(e)
                      }
                    >
                      {[5, 10, 15, 20].map((number) => (
                        <option key={number} value={number}>
                          {number} Shelves/page
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pagination">
                    {[...Array(totalOccupiedShelfPages).keys()].map(
                      (pageNumber) => (
                        <button
                          key={pageNumber}
                          type="button"
                          onClick={(e) =>
                            handlePageChangeOccupiedShelves(pageNumber + 1, e)
                          }
                          className={`page-link ${
                            occupiedShelfPage === pageNumber + 1 ? "active" : ""
                          }`}
                        >
                          {pageNumber + 1}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
            <Form.Group>
              <Form.Label>Select Shelf:</Form.Label>
              <Form.Control
                as="select"
                value={selectedShelf}
                onChange={(e) => setSelectedShelf(e.target.value)}
              >
                <option value="">Select Shelf</option>
                {shelves.map((shelf) => {
                  const remainingCapacity =
                    shelf.shelfCapacity - shelf.occupiedCapacity;
                  return (
                    <option key={shelf._id} value={shelf._id}>
                      {shelf.name} - {shelf.packageSize} (Remaining Capacity:{" "}
                      {remainingCapacity})
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Capacity Needed:</Form.Label>
              <Form.Control
                type="number"
                value={capacityNeeded}
                onChange={(e) => setCapacityNeeded(Number(e.target.value))}
                min="1"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Collection Type:</Form.Label>
              <Form.Control
                as="select"
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="handout">Handout</option>
                {userCurrentPlan === "Free" ? (
                  <option value="delivery" disabled>
                    Delivery
                  </option>
                ) : (
                  <option value="delivery">Delivery</option>
                )}
              </Form.Control>
            </Form.Group>
            {/* Delivery Fields - Conditionally Rendered */}
            {collectionType === "delivery" && (
              <>
                {/* Direct Delivery Question */}
                <Form.Group className="mt-4">
                  <Form.Check
                    type="checkbox"
                    label="Would you like to create a direct delivery for this collection?"
                    checked={wantDirectDelivery}
                    onChange={(e) => setWantDirectDelivery(e.target.checked)}
                  />
                </Form.Group>
              </>
            )}
            {/* Direct Delivery Form - Conditionally Rendered */}
            {wantDirectDelivery && (
              <div className="direct-delivery-form mt-3">
                <h5>Direct Delivery Details</h5>

                <Form.Group>
                  <Form.Label>Delivery Date:</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Type:</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryType}
                    onChange={(e) => setDeliveryType(e.target.value)}
                  >
                    <option value="regular">Regular</option>
                    <option value="express">Express</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Storage:</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryStorage}
                    onChange={(e) => setDeliveryStorage(e.target.value)}
                  >
                    <option value="ambient">Ambient</option>
                    <option value="refrigerated">Refrigerated</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Note:</Form.Label>
                  <Form.Control
                    type="text"
                    value={deliveryNote}
                    onChange={(e) => setDeliveryNote(e.target.value)}
                  />
                </Form.Group>
                {/* Route Selection */}
                <Form.Group>
                  <Form.Label>Route:</Form.Label>
                  <Form.Control
                    as="select"
                    value={routeRef}
                    onChange={(e) => setRouteRef(e.target.value)}
                  >
                    <option value="">Select a Route</option>
                    {routes
                      .filter((route) => route.activeStatus)
                      .map((route) => (
                        <option key={route._id} value={route._id}>
                          {route.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Payment Method:</Form.Label>
                  <Form.Control
                    as="select"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option value="exempt">Exempt</option>
                    <option value="collect">Collect</option>
                  </Form.Control>
                </Form.Group>

                {paymentMethod === "collect" && (
                  <>
                    <Form.Group>
                      <Form.Label>Payment Amount:</Form.Label>
                      <Form.Control
                        type="number"
                        value={paymentAmount}
                        onChange={(e) =>
                          setPaymentAmount(Number(e.target.value))
                        }
                        min="0"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Payment Type:</Form.Label>
                      <Form.Control
                        as="select"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        <option value="cash">Cash</option>
                      </Form.Control>
                    </Form.Group>
                  </>
                )}

                {/* Agreement Checkbox */}
                <Form.Group className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="I confirm that I want to create a direct delivery for this collection"
                    checked={agreeToDirectDelivery}
                    onChange={(e) => setAgreeToDirectDelivery(e.target.checked)}
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="medicine-storage-offcanvas-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddCollection}
            disabled={
              isProcessing ||
              (wantDirectDelivery && !agreeToDirectDelivery) ||
              (wantDirectDelivery && validateDeliveryFields())
            }
          >
            {isProcessing ? "Processing..." : "Save"}
          </Button>
        </div>
      </Offcanvas>

      {/* Shelf Capacity Modal */}
      <Offcanvas
        show={isTableOpen}
        onHide={toggleShelfModal}
        placement="end"
        className="medicine-storage-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="medicine-storage-offcanvas-header"
        >
          <Offcanvas.Title className="medicine-storage-offcanvas-title">
            Shelf Capacity
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="medicine-storage-offcanvas-body">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Package Size</th>
                <th>Occupied</th>
                <th>Remaining</th>
                <th>Capacity</th>
              </tr>
            </thead>
            <tbody>
              {shelves.map((shelf) => (
                <tr key={shelf._id}>
                  <td>{shelf.name}</td>
                  <td>{shelf.packageSize}</td>
                  <td>{shelf.occupiedCapacity}</td>
                  <td>
                    {remainingCapacity(
                      shelf.shelfCapacity,
                      shelf.occupiedCapacity
                    )}
                  </td>
                  <td>{shelf.shelfCapacity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Offcanvas.Body>
        <div className="medicine-storage-offcanvas-footer">
          <Button variant="secondary" onClick={toggleShelfModal}>
            Close
          </Button>
        </div>
      </Offcanvas>

      {/* Warning Modal */}
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        centered
        className="medicine-storage-warning-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <RiErrorWarningFill className="warning-icon" /> Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{warningMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowWarningModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setShowWarningModal(false);
              if (pendingCollectionAction) {
                pendingCollectionAction();
              }
            }}
          >
            Proceed Anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MedicineStorage;
