import { PATIENT_TYPES } from "../constants/patientTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createPatientByPharmacy,
  updatePatientByPharmacy,
  deletePatientByPharmacy,
  fetchPatientsByPharmacy,
} from "../../api/patientApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Patient Action
export const createPatientByPharmacyAction =
  (pharmacyId, patientData) => async (dispatch) => {
    console.log("====================================");
    console.log(patientData, "patientData in createPatientByPharmacyAction");
    console.log("====================================");
    try {
      const res = await createPatientByPharmacy(pharmacyId, patientData);
      dispatch({
        type: PATIENT_TYPES.CREATE_PATIENT,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Patient Action
export const updatePatientByPharmacyAction =
  (pharmacyId, id, patientData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updatePatientByPharmacy(pharmacyId, id, patientData);

      dispatch({
        type: PATIENT_TYPES.UPDATE_PATIENT,
        payload: { id, ...res.data },
      });

      SuccessNotification("Patient updated successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Patient Action
export const deletePatientByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deletePatientByPharmacy(pharmacyId, id);

      dispatch({
        type: PATIENT_TYPES.DELETE_PATIENT,
        payload: id,
      });

      SuccessNotification("Patient deleted successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Patients Action
export const fetchPatientsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchPatientsByPharmacy(pharmacyId);

      dispatch({
        type: PATIENT_TYPES.FETCH_PATIENTS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
