import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isSidebarExpanded, setSidebarIsExpanded] = useState(false);
  const [isHoverEnabled, setIsHoverEnabled] = useState(true);

  const toggleSidebar = () => setSidebarIsExpanded(!isSidebarExpanded);
  const showSidebar = () => setSidebarIsExpanded(true);
  const hideSidebar = () => setSidebarIsExpanded(false);

  const toggleHoverBehavior = () => {
    setIsHoverEnabled(!isHoverEnabled);
    if (isHoverEnabled) {
      setSidebarIsExpanded(true);
    }
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarExpanded,
        setSidebarIsExpanded,
        toggleSidebar,
        showSidebar,
        hideSidebar,
        isHoverEnabled,
        toggleHoverBehavior,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
