import React from "react";
import "./adminDashboardBox.css";
import { NavLink, useNavigate } from "react-router-dom";

function AdminDashboardBox({ indicators, pharmacyId }) {
  const navigate = useNavigate();

  const getNavigationPath = (title) => {
    switch (title) {
      case "Care Homes":
        return `/admin/${pharmacyId}/care-home`;
      case "Shelves":
        return `/admin/${pharmacyId}/collection-shelves`;
      case "Routes":
        return `/admin/${pharmacyId}/create-routes`;
      case "Vehicles":
        return `/admin/${pharmacyId}/vehicles`;
      case "Members":
        return `/admin/${pharmacyId}/members`;
      default:
        return "";
    }
  };

  const handleIconClick = (title) => {
    const path = getNavigationPath(title);
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <div className="management-dashboard-title">Management</div>
      <div className="dashboardMainBox">
        {indicators.map((indicator, index) => (
          <div key={index} className="dashSubBox">
            <div className="dashIconMainBox">
              <div
                className="dashIconContainer cursor-pointer"
                style={{
                  backgroundColor: indicator?.iconBackgroundColor,
                  cursor: "pointer",
                }}
                onClick={() => handleIconClick(indicator.title)}
                role="button"
                aria-label={`Navigate to ${indicator.title}`}
              >
                {React.cloneElement(indicator?.icon, {
                  style: { fontSize: "1.7rem", color: "fff" },
                })}
              </div>
              <NavLink
                to={getNavigationPath(indicator.title)}
                className="dashBoardNavLink color-muted"
              >
                View all {indicator.title.toLowerCase()}
              </NavLink>
            </div>
            <div className="dashInfoBox">
              <p className="dashTitle">{indicator?.title}</p>
              <p className="dashCount">{indicator?.count}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboardBox;
