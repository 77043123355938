import React, { useState } from "react";
import SuperAdminSidebar from "./components/SuperAdminSidebar";
import AdminSidebar from "./components/AdminSidebar";
import ManagerSideBar from "./components/ManagerSidebar";
import DriverSidebar from "./components/DriverSidebar";
import MenuHeader from "./components/MenuHeader";
import { useSidebar } from "../contexts/SidebarContext";
const VerticalLayout = ({ roles, pharmacyId, children, ...props }) => {
  const [visible, setVisible] = useState(false);
  const { isSidebarExpanded } = useSidebar();
  // Determine which sidebar to use based on the role
  let SidebarComponent;
  switch (roles) {
    case "superadmin":
      SidebarComponent = SuperAdminSidebar;
      break;
    case "admin":
      SidebarComponent = AdminSidebar;
      break;
    case "manager":
      SidebarComponent = ManagerSideBar;
      break;
    case "driver":
      SidebarComponent = DriverSidebar;
      break;
    default:
      SidebarComponent = null;
  }
  return (
    <div>
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        pharmacyId={pharmacyId}
      />

      <div
        style={{
          width: "100%",
          position: "fixed",
          zIndex: 999,
        }}
      >
        <MenuHeader setVisible={setVisible} visible={visible} />
      </div>

      {/* Main content outside the row of MenuHeader */}
      <div
        style={{
          marginLeft: isSidebarExpanded ? "220px" : "65px", // Adjust sizes as necessary

          transition: "margin-left 0.3s ease",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        {React.cloneElement(children, { sidebarVisible: visible })}
      </div>
    </div>
  );
};

export default VerticalLayout;
