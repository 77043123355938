import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markNotificationAsRead,
  fetchNotificationsByPharmacyAction,
} from "../../../redux/actions/notificationActions";
import { useParams } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import "./notifications.css";

function Notifications() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (sortConfig.key) {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];
      const comparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      return sortConfig.direction === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const toggleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(fetchNotificationsByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);

  const handleMarkAsRead = (notificationId) => {
    dispatch(markNotificationAsRead(pharmacyId, notificationId));
  };

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotifications = sortedNotifications.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(sortedNotifications.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (currentPage > 3) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="page-link"
        >
          First
        </button>
      );
    }

    if (currentPage > 1) {
      pageNumbers.push(
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          className="page-link"
        >
          Previous
        </button>
      );
    }

    if (currentPage <= 3) {
      for (let i = 1; i <= Math.min(5, totalPages); i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`page-link ${currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      }
      if (totalPages > 5) {
        pageNumbers.push(
          <span key="ellipsis" className="page-ellipsis">
            ...
          </span>
        );
        pageNumbers.push(
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            className="page-link"
          >
            {totalPages}
          </button>
        );
      }
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="page-link"
        >
          1
        </button>
      );
      pageNumbers.push(
        <span key="start-ellipsis" className="page-ellipsis">
          ...
        </span>
      );

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`page-link ${currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      }
      pageNumbers.push(
        <span key="end-ellipsis" className="page-ellipsis">
          ...
        </span>
      );
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="page-link"
        >
          {totalPages}
        </button>
      );
    } else {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="page-link"
        >
          1
        </button>
      );
      pageNumbers.push(
        <span key="ellipsis" className="page-ellipsis">
          ...
        </span>
      );
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`page-link ${currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      }
    }

    if (currentPage < totalPages) {
      pageNumbers.push(
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          className="page-link"
        >
          Next
        </button>
      );
    }

    if (currentPage < totalPages - 2) {
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="page-link"
        >
          Last
        </button>
      );
    }

    return pageNumbers;
  };

  const renderSortIcons = (key) => (
    <span className="sort-icons">
      <FaSortUp
        className={
          sortConfig.key === key && sortConfig.direction === "asc"
            ? "active"
            : ""
        }
      />
      <FaSortDown
        className={
          sortConfig.key === key && sortConfig.direction === "desc"
            ? "active"
            : ""
        }
      />
    </span>
  );

  return (
    <>
      <div className="mt-100 mb-30">
        <div className="container-fluid">
          <div className="table-container">
            <table className="table table-bordered w-100">
              <thead>
                <tr className="notification-header">
                  <th>Content</th>
                  <th onClick={() => toggleSort("priority")}>
                    <div className="d-flex align-items-center">
                      Priority {renderSortIcons("priority")}
                    </div>
                  </th>
                  <th onClick={() => toggleSort("forRole")}>
                    <div className="d-flex align-items-center">
                      Role {renderSortIcons("forRole")}
                    </div>
                  </th>
                  <th onClick={() => toggleSort("createdAt")}>
                    <div className="d-flex align-items-center">
                      Date {renderSortIcons("createdAt")}
                    </div>
                  </th>
                  <th onClick={() => toggleSort("isRead")}>
                    <div className="d-flex align-items-center">
                      Status {renderSortIcons("isRead")}
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentNotifications.length > 0 ? (
                  currentNotifications.map((notification) => (
                    <tr key={notification._id}>
                      <td>{notification.content}</td>
                      <td>
                        {notification.priority.charAt(0).toUpperCase() +
                          notification.priority.slice(1)}
                      </td>
                      <td>
                        {notification.forRole
                          .map(
                            (role) =>
                              role.charAt(0).toUpperCase() + role.slice(1)
                          )
                          .join(", ")}
                      </td>
                      <td>
                        {new Date(notification.createdAt).toLocaleString()}
                      </td>
                      <td>{notification.isRead ? "Read" : "Unread"}</td>
                      <td>
                        {!notification.isRead && (
                          <button
                            className="btn mark-read-btn"
                            onClick={() => handleMarkAsRead(notification._id)}
                          >
                            Mark as Read
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No notifications found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="page-number-box">
            <div>
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[5, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number}/page
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination">{renderPageNumbers()}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
