import React, { useEffect, useState } from "react";
import {
  fetchMdsByPharmacyAction,
  updateMdsStatusByPharmacyAction,
} from "../../../redux/actions/mdsActions";
import { FaEye, FaPlus, FaTrashAlt } from "react-icons/fa";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { createMdsWithoutRepeatCycleByPharmacyAction } from "../../../redux/actions/mdsActions";
import {
  fetchShelvesByPharmacyAction,
  assignShelfAction,
} from "../../../redux/actions/shelveAction";
import { createDeliveryByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import {
  Button,
  Modal,
  Table,
  Form,
  ListGroup,
  Row,
  Col,
  Accordion,
  Card,
  Offcanvas,
} from "react-bootstrap";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./updateRx.css";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { FiEdit } from "react-icons/fi";
import { BsClock } from "react-icons/bs";
export default function UpdateRx() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("date");
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedMds, setSelectedMds] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [statusHistory, setStatusHistory] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCreateMdsModal, setShowCreateMdsModal] = useState(false);
  const [searchPatientTerm, setSearchPatientTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [mdsDetails, setMdsDetails] = useState({
    start: "",
    end: "",
    medicationDetails: [{ name: "", dosage: "", quantity: "" }],
    status: "Pending",
  });
  const [activeKey, setActiveKey] = useState(null);
  const [pagination, setPagination] = useState({});
  // Collections
  const [selectedShelf, setSelectedShelf] = useState("");
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  const [capacityNeeded, setCapacityNeeded] = useState(1);
  const [collectionType, setCollectionType] = useState("handout");
  const [medicationNameFilter, setMedicationNameFilter] = useState("");
  // Delivery// Add these new state variables at the top with other state declarations
  const [wantDirectDelivery, setWantDirectDelivery] = useState(false);
  const [agreeToDirectDelivery, setAgreeToDirectDelivery] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryType, setDeliveryType] = useState("regular");
  const [deliveryStorage, setDeliveryStorage] = useState("ambient");
  const [deliveryStatus, setDeliveryStatus] = useState("assigned-driver");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [routeRef, setRouteRef] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("exempt");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("cash");
  const [isProcessing, setIsProcessing] = useState(false);

  // Use Selectors
  const user = useSelector((state) => state.auth.user);
  const mdsData = useSelector((state) => state.mds.mdsState);
  const allPatients = useSelector((state) => state.patient.patients);
  const routes = useSelector((state) => state.route.routes);
  const shelves = useSelector((state) => state.shelve.shelves);

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {});
  };

  const handleOpenCreateMdsModal = () => {
    setShowCreateMdsModal(true);
  };
  const handleCloseCreateMdsModal = () => {
    setMdsDetails({
      start: "",
      end: "",
      medicationDetails: [{ name: "", dosage: "", quantity: "" }],
      status: "Pending",
    });
    setSearchPatientTerm("");
    setSelectedPatient(null);
    setShowCreateMdsModal(false);
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    setSearchPatientTerm(`${patient.firstName} ${patient.lastName}`);
    setFilteredPatients([]);
  };

  const handleStartDateChange = (e) => {
    const newStart = e.target.value;
    setMdsDetails((prevDetails) => ({
      ...prevDetails,
      start: newStart,
      // Automatically update the end date if it's not already set or is the same as the old start date
      end:
        !prevDetails.end || prevDetails.end === prevDetails.start
          ? newStart
          : prevDetails.end,
    }));
  };

  const handleEndDateChange = (e) => {
    setMdsDetails((prevDetails) => ({
      ...prevDetails,
      end: e.target.value,
    }));
  };

  const handleMedicationChange = (index, field, value) => {
    const newMedicationDetails = [...mdsDetails.medicationDetails];
    newMedicationDetails[index] = {
      ...newMedicationDetails[index],
      [field]: value,
    };
    setMdsDetails({ ...mdsDetails, medicationDetails: newMedicationDetails });
  };

  const addMedicationDetail = () => {
    setMdsDetails({
      ...mdsDetails,
      medicationDetails: [
        ...mdsDetails.medicationDetails,
        { name: "", dosage: "", quantity: "" },
      ],
    });
  };

  const removeMedicationDetail = (index) => {
    const newMedicationDetails = [...mdsDetails.medicationDetails];
    newMedicationDetails.splice(index, 1);
    setMdsDetails({ ...mdsDetails, medicationDetails: newMedicationDetails });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mdsData = {
      ...mdsDetails,
      patient: selectedPatient._id,
      pharmacyId,
    };
    dispatch(createMdsWithoutRepeatCycleByPharmacyAction(pharmacyId, mdsData));
    dispatch(fetchMdsByPharmacyAction(pharmacyId));
    // console.log(mdsData, "sending Data to create Mds");
    setShowCreateMdsModal(false);
    setMdsDetails({
      start: "",
      end: "",
      medicationDetails: [{ name: "", dosage: "", quantity: "" }],
      status: "Pending",
    });
    setSearchPatientTerm("");
    setSelectedPatient(null);
  };

  // Helper function to group MDS data by patient name
  const groupMdsByPatient = (mdsEntries) => {
    return mdsEntries.reduce((acc, mds) => {
      const patientKey = mds.patient ? mds.patient._id : null;
      const cycleKey = mds.mdsRepeatCycle;

      if (patientKey && cycleKey) {
        if (!acc[patientKey]) {
          acc[patientKey] = {
            name: `${mds.patient.firstName} ${mds.patient.lastName}`,
            cycles: {},
          };
        }
        if (!acc[patientKey].cycles[cycleKey]) {
          acc[patientKey].cycles[cycleKey] = {
            entries: [],
          };
        }
        acc[patientKey].cycles[cycleKey].entries.push(mds);
      }
      return acc;
    }, {});
  };

  const handlePageChange = (cycleId, page) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], currentPage: page },
    }));
  };

  const handleItemsPerPageChange = (cycleId, items) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], itemsPerPage: items, currentPage: 1 },
    }));
  };
  const applyPagination = (entries, cycleId) => {
    const page = pagination[cycleId]?.currentPage || 1;
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const offset = (page - 1) * itemsPerPage;
    return Array.isArray(entries)
      ? entries.slice(offset, offset + itemsPerPage)
      : [];
  };
  const renderCyclePagination = (cycleId, totalEntries) => {
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const currentPage = pagination[cycleId]?.currentPage || 1;
    const totalPageNumbers = Math.ceil(totalEntries / itemsPerPage);

    const pageNumbers = [];
    for (let number = 1; number <= totalPageNumbers; number++) {
      pageNumbers.push(
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <button
            onClick={() => handlePageChange(cycleId, number)}
            className="update-rx-pagination-btn"
          >
            {number}
          </button>
        </li>
      );
    }

    return (
      <nav>
        <ul className="custom-table-pagination">{pageNumbers}</ul>
      </nav>
    );
  };

  const renderItemsPerPageSelector = (cycleId) => {
    return (
      <div className="items-per-page-selector">
        <select
          value={pagination[cycleId]?.itemsPerPage || 5}
          onChange={(e) =>
            handleItemsPerPageChange(cycleId, Number(e.target.value))
          }
        >
          {[5, 10, 15, 20, 25].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const applyFiltersAndSorting = (data) => {
    // Filter by dates first
    let filteredData = data.filter((mds) => {
      let startDate = new Date(mds.start);
      let filterFromDate = fromDate
        ? new Date(fromDate)
        : new Date("1900-01-01");
      let filterToDate = toDate ? new Date(toDate) : new Date("2100-01-01");

      // Check if any status in the statusHistory is 'Query - Resolved'
      const hasQueryResolved = mds.statusHistory.some(
        (status) => status.status === "Query - Resolved"
      );
      return (
        startDate >= filterFromDate &&
        startDate <= filterToDate &&
        !hasQueryResolved
      );
    });

    // Apply search query filter
    filteredData = filteredData.filter((mds) => {
      const fullName =
        `${mds.patient.firstName} ${mds.patient.lastName}`.toLowerCase();
      return (
        searchQuery.toLowerCase() === "" ||
        fullName.includes(searchQuery.toLowerCase()) ||
        mds.patient.nhsNumber.toString().includes(searchQuery)
      );
    });

    // Apply medication name filter
    if (medicationNameFilter) {
      filteredData = filteredData.filter((mds) =>
        mds.medicationDetails.some((detail) =>
          detail.name.toLowerCase().includes(medicationNameFilter.toLowerCase())
        )
      );
    }

    // Sort based on the current sort settings
    return filteredData.sort((a, b) => {
      let compareValue = 0;
      if (sortBy === "patientName") {
        compareValue = (a.patient?.firstName ?? "").localeCompare(
          b.patient?.firstName ?? ""
        );
      } else if (sortBy === "nhsNumber") {
        compareValue = (a.patient?.nhsNumber?.toString() ?? "").localeCompare(
          b.patient?.nhsNumber?.toString() ?? ""
        );
      } else if (sortBy === "date") {
        compareValue = new Date(a.start) - new Date(b.start);
      } else if (sortBy === "status") {
        compareValue = (a.status ?? "").localeCompare(b.status ?? "");
      }
      return sortDirection === "asc" ? compareValue : -compareValue;
    });
  };

  // Group the filtered and sorted data for display in the accordion
  const groupedData = groupMdsByPatient(applyFiltersAndSorting(mdsData));
  // Pagination setup for grouped data
  const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <li key={number} className="page-number-box">
      <button
        className="update-rx-pagination-btn"
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </button>
    </li>
  ));

  const handleUpdateStatus = async () => {
    if (!newStatus) {
      ErrorNotification("Please select a status before updating.");
      return;
    }

    try {
      // Dispatch the status update action
      dispatch(
        updateMdsStatusByPharmacyAction(pharmacyId, selectedMds._id, newStatus)
      );
      // Create a notification after the status update is successful
      const notificationContent = `Status of MDS for patient ${selectedMds.patient.firstName} ${selectedMds.patient.lastName} updated to ${newStatus} .`;
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: notificationContent,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "medium",
        })
      );
      setShowModal(false); // Close the modal after update
      setSelectedPatient(null);
    } catch (error) {
      console.error("Error updating status:", error);
      ErrorNotification("An error occurred while updating the status.");
    }
  };

  const handleStatusChange = (e) => {
    const newStatusValue = e.target.value;
    setNewStatus(newStatusValue); // Update the status state

    // Check if the new status is 'DosettePrepared' and a patient is selected
    if (newStatusValue === "Dosette Prepared") {
      setShowAddCollectionModal(true);
    } else {
      setShowAddCollectionModal(false); // Optionally hide the modal if the condition is not met
    }
  };

  const closeShowStatusModal = () => {
    setShowModal(false);
    setNewStatus("");
    setSelectedPatient(null);
  };

  const handleAddCollection = () => {
    setIsProcessing(true);
    const shelfName = shelves.find(
      (shelf) => shelf._id === selectedShelf
    )?.name;

    if (!selectedShelf || !selectedPatient._id || !collectionType) {
      ErrorNotification(
        "Missing required fields for assigning a shelf",
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      );
      setIsProcessing(false);
      return;
    }

    dispatch(
      assignShelfAction(
        pharmacyId,
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      )
    )
      .then((res) => {
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));

        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `${selectedPatient.firstName} ${selectedPatient.lastName} bag stored in ${shelfName} shelf.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );

        if (
          collectionType === "delivery" &&
          wantDirectDelivery &&
          agreeToDirectDelivery
        ) {
          return handleAddDelivery(res.collectionRef);
        }
      })
      .then(() => {
        // Reset all states
        setSelectedShelf("");
        setCapacityNeeded(1);
        setShowAddCollectionModal(false);
        setWantDirectDelivery(false);
        setAgreeToDirectDelivery(false);
        setDeliveryDate("");
        setDeliveryType("regular");
        setDeliveryStorage("ambient");
        setDeliveryNote("");
        setRouteRef("");
        setPaymentMethod("exempt");
        setPaymentAmount(0);
        setPaymentType("cash");
      })
      .catch((error) => {
        console.error("Error assigning shelf or creating delivery:", error);
        ErrorNotification(error);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleAddDelivery = (collectionRef) => {
    const deliveryData = {
      deliveryDate,
      deliveryType,
      deliveryStorage,
      deliveryNote,
      status: "assigned-driver",
      patientRef: selectedPatient._id,
      collectionRef,
      routeRef,
      paymentOnDelivery: {
        method: paymentMethod,
        status: paymentMethod === "exempt" ? "collected" : "pending",
        amount: paymentAmount,
        paymentType,
      },
    };

    return dispatch(createDeliveryByPharmacyAction(pharmacyId, deliveryData))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Delivery created successfully for ${selectedPatient.firstName} with date ${deliveryDate}.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
      })
      .catch((error) => {
        console.error("Error creating delivery:", error);
        ErrorNotification("Failed to create delivery.");
        throw error; // Re-throw to be caught by the main catch block
      });
  };

  // To navigate to request-rx page when clicking on the time slot
  const handleTimeClick = (event) => {
    // Store the complete event data
    const eventData = {
      ...event,
      patient: event.patient,
      medicationDetails: event.medicationDetails,
      start: event.start,
      end: event.end,
      mdsRepeatCycle: event.mdsRepeatCycle,
      statusHistory: event.statusHistory,
    };

    sessionStorage.setItem("selectedMdsEvent", JSON.stringify(eventData));
    navigate(`/admin/${pharmacyId}/request-rx`);
  };

  useEffect(() => {
    if (searchPatientTerm) {
      const filtered = allPatients
        .filter(
          (patient) =>
            patient.displayName
              .toLowerCase()
              .includes(searchPatientTerm.toLowerCase()) ||
            patient.nhsNumber.includes(searchPatientTerm)
        )
        .slice(0, 5); // Limit to 5 results
      setFilteredPatients(filtered);
    } else {
      setFilteredPatients([]);
    }
  }, [searchPatientTerm, allPatients]);

  useEffect(() => {
    dispatch(fetchMdsByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching pharmacy:", error);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  }, [dispatch, pharmacyId]);

  const getRoutes = () => {
    dispatch(fetchRoutesByPharmacyAction(pharmacyId));
  };

  useEffect(() => {
    getPatients();
    getRoutes();
  }, []);
  return (
    <div className="update-rx-container">
      <div className="update-rx-search-input-box">
        <input
          type="text"
          placeholder="Search by name or NHS number..."
          className="update-rx-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="update-rx-header">
        <Button
          className="update-rx-invite-btn"
          onClick={handleOpenCreateMdsModal}
        >
          Add Patient Without Cycle
        </Button>

        <div className="update-rx-filter-box">
          <div className="update-rx-medication-filter-box">
            <input
              type="text"
              placeholder="Filter by medication name..."
              className="update-rx-medication-filter-input"
              value={medicationNameFilter}
              onChange={(e) => setMedicationNameFilter(e.target.value)}
            />
          </div>

          <div className="update-rx-date-filter-box">
            <input
              type="date"
              className="update-rx-date-input"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <span className="update-rx-date-filter-text">to</span>
            <input
              type="date"
              className="update-rx-date-input"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            <button
              className="update-rx-date-filter-button"
              onClick={resetFilters}
            >
              Reset Filters
            </button>
          </div>
        </div>
      </div>

      <div className="update-rx-items-per-container">
        <div className="update-rx-items-per-container-item">
          <BsClock className="update-rx-clock" />
          <label className="update-rx-clock-label">Update Repeat Cycle</label>
        </div>
        <select
          className="update-rx-form-select"
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
        >
          {[5, 10, 15, 20].map((number) => (
            <option
              key={number}
              value={number}
              className="update-rx-per-page-option"
            >
              {number} Mds/page
            </option>
          ))}
        </select>
      </div>
      <div className="update-rx-accordion-container">
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          {Object.entries(groupedData).map(([patientId, patientData]) => (
            <Accordion.Item eventKey={patientId} key={patientId}>
              <Accordion.Header
                onClick={() =>
                  setActiveKey(activeKey === patientId ? null : patientId)
                }
              >
                <div>
                  <p className="update-rx-accordion-patient-name">
                    {patientData.name}
                  </p>
                  <p className="update-rx-accordion-patient-total-cycles">
                    Total Cycles: {Object.keys(patientData.cycles).length}
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Collapse eventKey={patientId}>
                <Card.Body>
                  {Object.entries(patientData.cycles).map(
                    ([cycleId, cycleData], index) => (
                      <div key={cycleId} className="update-rx-table-container">
                        {/* Cycle Name */}
                        <div className="update-rx-cycle-name-box">
                          <p className="update-rx-cycle-name">
                            Cycle {index + 1}:{" "}
                            <span className="update-rx-cycle-medication-name">
                              {cycleData.entries[0]?.medicationDetails
                                .map((detail) => detail.name)
                                .join(", ")}
                            </span>
                          </p>
                          <BsClock
                            className="update-rx-clock"
                            onClick={() =>
                              handleTimeClick(cycleData.entries[0])
                            }
                          />
                        </div>
                        <table className="table table-bordered w-100">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Medication Name (Dosage)</th>
                              <th>Quantity</th>
                              <th>Status</th>
                              <th>Update</th>
                              <th>View Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {applyPagination(cycleData.entries, cycleId).map(
                              (mds) => (
                                <tr key={mds._id}>
                                  <td>
                                    {new Date(mds.start).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "2-digit",
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {mds?.medicationDetails
                                      .map(
                                        (detail) =>
                                          `${detail.name} (${detail.dosage})`
                                      )
                                      .join(", ")}
                                  </td>
                                  <td>
                                    {mds?.medicationDetails.map(
                                      (detail) => detail.quantity
                                    )}
                                  </td>
                                  <td>
                                    {
                                      mds.statusHistory[
                                        mds.statusHistory.length - 1
                                      ].status
                                    }
                                  </td>
                                  <td>
                                    <FiEdit
                                      onClick={() => {
                                        setSelectedMds(mds);
                                        setShowModal(true);
                                        setSelectedPatient(mds.patient);
                                      }}
                                      className="update-rx-action-icon update-rx-edit-icon"
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      variant="btn"
                                      onClick={() => {
                                        setStatusHistory(mds.statusHistory);
                                        setShowStatusModal(true);
                                      }}
                                    >
                                      <FaEye />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <div className="mds-table-pagination-nav-box">
                          {renderCyclePagination(
                            cycleId,
                            cycleData.entries.length
                          )}
                          {renderItemsPerPageSelector(cycleId)}
                        </div>
                      </div>
                    )
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <div className="page-number-box">
        <nav>
          <ul className="pagination">{renderPageNumbers}</ul>
        </nav>
      </div>

      {/* Modal for updating status */}
      <Offcanvas
        show={showModal}
        onHide={closeShowStatusModal}
        placement="end"
        className="update-rx-offcanvas"
      >
        <Offcanvas.Header closeButton className="update-rx-offcanvas-header">
          <Offcanvas.Title className="update-rx-offcanvas-title">
            Update MDS Status
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="update-rx-offcanvas-body">
          {selectedMds && (
            <div>
              <p>
                <b>Patient Name</b>: {selectedMds.patient.firstName}{" "}
                {selectedMds.patient.lastName}
              </p>
              <p>
                <b>Medication Details</b>:{" "}
                {selectedMds.medicationDetails[0].name}{" "}
                {selectedMds.medicationDetails[0].dosage}
              </p>
              <p>
                <b>Prescription Status</b>: {selectedMds.status}
              </p>
            </div>
          )}
          <Form>
            <Form.Group controlId="statusSelect">
              <Form.Label>Select New Status</Form.Label>
              <Form.Control
                as="select"
                value={newStatus}
                onChange={handleStatusChange}
              >
                <option value="">Select Status</option>
                <option value="Rx Requested">Rx Requested</option>
                <option value="Rx Received">Rx Received</option>
                <option value="Rx Processed">Rx Processed</option>
                <option value="Dosette Prepared">Dosette Prepared</option>
                <option value="Query - Contacted GP">
                  Query - Contacted GP
                </option>
                <option value="Query - Resolved">Query - Resolved</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="update-rx-offcanvas-footer">
          <Button variant="primary" onClick={handleUpdateStatus}>
            Save Changes
          </Button>
        </div>
      </Offcanvas>

      {/* Status Modal */}
      <Offcanvas
        show={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        placement="end"
        className="update-rx-offcanvas"
      >
        <Offcanvas.Header closeButton className="update-rx-offcanvas-header">
          <Offcanvas.Title className="update-rx-offcanvas-title">
            Status History
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="update-rx-offcanvas-body">
          <div className="status-timeline">
            {[...statusHistory].reverse().map((history, index, arr) => (
              <div
                key={index}
                className={`status-timeline-item ${
                  index === 0 ? "latest" : ""
                }`}
              >
                <div className="status-timeline-dot"></div>
                <div className="status-timeline-content">
                  <p>
                    <strong>Status:</strong> {history.status}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {new Date(history.date).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Modal to  create Mds */}
      <Offcanvas
        show={showCreateMdsModal}
        onHide={handleCloseCreateMdsModal}
        placement="end"
        className="update-rx-offcanvas"
      >
        <Offcanvas.Header closeButton className="update-rx-offcanvas-header">
          <Offcanvas.Title className="update-rx-offcanvas-title">
            Create Non MDS Patient
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="update-rx-offcanvas-body">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Search Patient by Name or NHS Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchPatientTerm}
                onChange={(e) => setSearchPatientTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && filteredPatients.length > 0) {
                    e.preventDefault(); // Prevent form submit
                    handleSelectPatient(filteredPatients[0]);
                  }
                }}
                disabled={!!selectedPatient}
              />
              <ListGroup>
                {filteredPatients.map((patient) => (
                  <ListGroup.Item
                    key={patient._id}
                    onClick={() => handleSelectPatient(patient)}
                  >
                    {patient.firstName} {patient.lastName} - {patient.nhsNumber}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
            {selectedPatient && (
              <>
                <Form.Group>
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>NHS Number</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={selectedPatient.nhsNumber}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={mdsDetails.start}
                    onChange={handleStartDateChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={mdsDetails.end}
                    onChange={handleEndDateChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Medication Details</Form.Label>

                  {mdsDetails.medicationDetails.map((medication, index) => (
                    <Row key={index} className="mb-2 align-items-center">
                      <Col md={4}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter medication name"
                            value={medication.name}
                            onChange={(e) =>
                              handleMedicationChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Dosage"
                            value={medication.dosage}
                            onChange={(e) =>
                              handleMedicationChange(
                                index,
                                "dosage",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Control
                            type="number"
                            placeholder="Quantity"
                            value={medication.quantity}
                            onChange={(e) =>
                              handleMedicationChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Button
                          variant="link"
                          onClick={() => removeMedicationDetail(index)}
                          className="text-danger p-0"
                        >
                          <FaTrashAlt />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Button
                    variant="link"
                    onClick={addMedicationDetail}
                    className="text-success mt-2"
                  >
                    <FaPlus /> Add Another Medication
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={mdsDetails.status}
                    onChange={(e) =>
                      setMdsDetails({ ...mdsDetails, status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="Rx Requested">Rx Requested</option>
                    <option value="Rx Received">Rx Received</option>
                    <option value="Rx Processed">Rx Processed</option>
                    <option value="Dosette Prepared">Dosette Prepared</option>
                    <option value="Query - Contacted GP">
                      Query - Contacted GP
                    </option>
                    <option value="Query - Resolved">Query - Resolved</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="update-rx-offcanvas-footer">
          <Button variant="secondary" onClick={handleCloseCreateMdsModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={!selectedPatient}>
            Create MDS
          </Button>
        </div>
      </Offcanvas>

      {/* Add to collection Modal */}
      <Offcanvas
        show={showAddCollectionModal}
        onHide={() => setShowAddCollectionModal(false)}
        placement="end"
        className="update-rx-offcanvas"
      >
        <Offcanvas.Header closeButton className="update-rx-offcanvas-header">
          <Offcanvas.Title className="update-rx-offcanvas-title">
            Assign shelf a collection
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="update-rx-offcanvas-body">
          <Form>
            <Form.Group>
              <Form.Label>Package Size:</Form.Label>
              <Form.Control
                as="select"
                value={selectedShelf}
                onChange={(e) => setSelectedShelf(e.target.value)}
              >
                <option>Select Shelf</option>
                {shelves.map((shelf) => (
                  <option key={shelf._id} value={shelf._id}>
                    {shelf.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Capacity Needed:</Form.Label>
              <Form.Control
                type="number" // Ensure this is set to 'number'
                value={capacityNeeded}
                onChange={(e) => setCapacityNeeded(Number(e.target.value))}
                min="1" // Optional: Ensure that no invalid numbers can be entered
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Collection Type:</Form.Label>
              <Form.Control
                as="select"
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="handout">handout</option>
                <option value="delivery">delivery</option>
              </Form.Control>
            </Form.Group>
            {/* Delivery Fields - Conditionally Rendered */}
            {collectionType === "delivery" && (
              <>
                {/* Direct Delivery Question */}
                <Form.Group className="mt-4">
                  <Form.Check
                    type="checkbox"
                    label="Would you like to create a direct delivery for this collection?"
                    checked={wantDirectDelivery}
                    onChange={(e) => setWantDirectDelivery(e.target.checked)}
                  />
                </Form.Group>
              </>
            )}
            {/* Direct Delivery Form */}
            {collectionType === "delivery" && wantDirectDelivery && (
              <div className="direct-delivery-form mt-3">
                <h5>Direct Delivery Details</h5>

                <Form.Group>
                  <Form.Label>Delivery Date:</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Type:</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryType}
                    onChange={(e) => setDeliveryType(e.target.value)}
                  >
                    <option value="regular">Regular</option>
                    <option value="express">Express</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Storage:</Form.Label>
                  <Form.Control
                    as="select"
                    value={deliveryStorage}
                    onChange={(e) => setDeliveryStorage(e.target.value)}
                  >
                    <option value="ambient">Ambient</option>
                    <option value="refrigerated">Refrigerated</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Delivery Note:</Form.Label>
                  <Form.Control
                    type="text"
                    value={deliveryNote}
                    onChange={(e) => setDeliveryNote(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Route:</Form.Label>
                  <Form.Control
                    as="select"
                    value={routeRef}
                    onChange={(e) => setRouteRef(e.target.value)}
                  >
                    <option value="">Select a Route</option>
                    {routes
                      .filter((route) => route.activeStatus)
                      .map((route) => (
                        <option key={route._id} value={route._id}>
                          {route.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Payment Method:</Form.Label>
                  <Form.Control
                    as="select"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option value="exempt">Exempt</option>
                    <option value="collect">Collect</option>
                  </Form.Control>
                </Form.Group>

                {paymentMethod === "collect" && (
                  <>
                    <Form.Group>
                      <Form.Label>Payment Amount:</Form.Label>
                      <Form.Control
                        type="number"
                        value={paymentAmount}
                        onChange={(e) =>
                          setPaymentAmount(Number(e.target.value))
                        }
                        min="0"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Payment Type:</Form.Label>
                      <Form.Control
                        as="select"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                      >
                        <option value="cash">Cash</option>
                      </Form.Control>
                    </Form.Group>
                  </>
                )}

                {/* Agreement Checkbox */}
                <Form.Group className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="I confirm that I want to create a direct delivery for this collection"
                    checked={agreeToDirectDelivery}
                    onChange={(e) => setAgreeToDirectDelivery(e.target.checked)}
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="update-rx-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={() => setShowAddCollectionModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCollection}>
            Save
          </Button>
        </div>
      </Offcanvas>
    </div>
  );
}
